import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import OfficeUserMappingList from "@components/office-user-mapping/List"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "office-user-mapping.getAll",
  query: 'deleteAttempt=0'
}

const OfficeUserMappingListPage = (props) => (
  <>
    <Seo title="Office-User Mapping List" />
    <div className="main-wrapper">
      <Sidebar menuActive="settings"
        action="List" />
      <OfficeUserMappingList
        addBtnText="Office User Mapping"
        menu="Office-User Mapping"
        parentPath="office-user-mapping"
        pageType="List"
        searchBoxSuffixText="Office"
        additionalParams={AdditionalParams}
      />
    </div>
  </>
)

export default OfficeUserMappingListPage;
